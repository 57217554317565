import React from "react";

import { loadConfig, adblockDetect } from "api";
import { asyncComponent, getFavicon } from "utils";
import Redirect from "./components/Redirect";

import LiRuCounter from "./components/LiRuCounter";
import YaMetrica from "./components/YaMetrica";
import ReTarget from "./components/ReTarget";
import CookieFrame from "./components/CookieFrame";
import SmiWebpush from "./components/SmiWebpush";
import { ConfigContext } from "./context";
import getConfig from "config";
import { TrackerProvider } from "./components/TrackerProvider";

import { BackRedirectDetect } from "handlers/BackRedirect";

const Top1View = asyncComponent(() => import("views/top3columns/Top1"));
const Top133View = asyncComponent(() => import("views/top3columns/Top133"));

const Top777_1 = asyncComponent(() => import("views/top777_1"));
const Top780 = asyncComponent(() => import("views/top780"));

const TopStoryView = asyncComponent(() =>
  import("views/top_story/MainTopEntry")
);
const TopStoryV3 = asyncComponent(() => import("views/top_story/V3TopEntry"));

const Top800 = asyncComponent(() => import("views/top800/MainTopEntry"));
const Top900 = asyncComponent(() => import("views/top900"));
const Top901 = asyncComponent(() => import("views/top901"));
const Top902 = asyncComponent(() => import("views/top902"));
const Top903 = asyncComponent(() => import("views/top903"));
const Top904 = asyncComponent(() => import("views/top904"));

const Top910 = asyncComponent(() => import("views/top910"));
const Top911 = asyncComponent(() => import("views/top911"));
const Top912 = asyncComponent(() => import("views/top912"));
const Top913 = asyncComponent(() => import("views/top913"));

const Top914 = asyncComponent(() => import("views/top914"));
const Top915 = asyncComponent(() => import("views/top915"));
const Top916 = asyncComponent(() => import("views/top916"));
const Top917 = asyncComponent(() => import("views/top917"));

/* Тесты левого сайдбара (Десктоп) */
const Top918 = asyncComponent(() => import("views/top918"));
const Top919 = asyncComponent(() => import("views/top919"));
const Top920 = asyncComponent(() => import("views/top920"));
const Top921 = asyncComponent(() => import("views/top921"));

/* Тесты правого сайдбара (Десктоп) */
const Top922 = asyncComponent(() => import("views/top922"));
const Top923 = asyncComponent(() => import("views/top923"));
const Top924 = asyncComponent(() => import("views/top924"));
const Top925 = asyncComponent(() => import("views/top925"));

/* Тесты ленты (Десктоп) */
const Top926 = asyncComponent(() => import("views/top926"));
const Top927 = asyncComponent(() => import("views/top927"));
const Top928 = asyncComponent(() => import("views/top928"));
const Top929 = asyncComponent(() => import("views/top929"));

/* Тесты ленты (Мобайл) */
const Top930 = asyncComponent(() => import("views/top930"));
const Top931 = asyncComponent(() => import("views/top931"));
const Top932 = asyncComponent(() => import("views/top932"));
const Top933 = asyncComponent(() => import("views/top933"));
const Top934 = asyncComponent(() => import("views/top934"));

/* 1000 (Зелёный) */
const Top1001 = asyncComponent(() => import("views/top1001"));
const Top1002 = asyncComponent(() => import("views/top1002"));
const Top1003 = asyncComponent(() => import("views/top1003"));
const Top1004 = asyncComponent(() => import("views/top1004"));

/* 1000 (Синий) */
const Top1011 = asyncComponent(() => import("views/top1011"));
const Top1012 = asyncComponent(() => import("views/top1012"));
const Top1013 = asyncComponent(() => import("views/top1013"));
const Top1014 = asyncComponent(() => import("views/top1014"));

/* 1000 (Красный) */
const Top1021 = asyncComponent(() => import("views/top1021"));
const Top1022 = asyncComponent(() => import("views/top1022"));
const Top1023 = asyncComponent(() => import("views/top1023"));
const Top1024 = asyncComponent(() => import("views/top1024"));

/* 1000 (Оранжевый) */
const Top1031 = asyncComponent(() => import("views/top1031"));
const Top1032 = asyncComponent(() => import("views/top1032"));
const Top1033 = asyncComponent(() => import("views/top1033"));
const Top1034 = asyncComponent(() => import("views/top1034"));

/* 1100 */
const Top1100 = asyncComponent(() => import("views/top1100"));
const Top1101 = asyncComponent(() => import("views/top1101"));
const Top1102 = asyncComponent(() => import("views/top1102"));
const Top1103 = asyncComponent(() => import("views/top1103"));
const Top1104 = asyncComponent(() => import("views/top1104"));
const Top1105 = asyncComponent(() => import("views/top1105"));
const Top1106 = asyncComponent(() => import("views/top1106"));
const Top1107 = asyncComponent(() => import("views/top1107"));
const Top1108 = asyncComponent(() => import("views/top1108"));
const Top1109 = asyncComponent(() => import("views/top1109"));

/* 120X */
const Top1201 = asyncComponent(() => import("views/top1201"));
const Top1202 = asyncComponent(() => import("views/top1202"));
const Top1203 = asyncComponent(() => import("views/top1203"));
const Top1204 = asyncComponent(() => import("views/top1204"));
const Top1205 = asyncComponent(() => import("views/top1205"));
const Top1206 = asyncComponent(() => import("views/top1206"));
const Top1207 = asyncComponent(() => import("views/top1207"));
const Top1208 = asyncComponent(() => import("views/top1208"));
const Top1209 = asyncComponent(() => import("views/top1209"));

/* 130X */
const Top1301 = asyncComponent(() => import("views/top1301"));

const Top779 = asyncComponent(() => import("views/top779"));

const WEBPUSH_DISABLED_SOURCES = `${process.env
  .REACT_APP_WEBPUSH_DISABLED_SOURCES || ""}`.split(",");

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configured: false,
      configuration: {},
      mainNews: null,
      yaCounterReady: false,
      redirect: false
    };

    document.addEventListener(
      `yacounter${process.env.REACT_APP_YA_COUNTE_ID}inited`,
      () => {
        this.setState({ yaCounterReady: true });
      }
    );
  }

  updateMainNews(mainNews, cb) {
    this.setState(() => {
      return { mainNews };
    }, cb);
  }

  getView() {
    switch (this.state.configuration.template) {
      case 2:
        return Top1View;

      case 7:
        return Top133View;

      case 700:
        return TopStoryView;

      case 701:
        return TopStoryV3;

      case 778:
        return Top777_1;

      case 779:
        return Top779;

      case 780:
        return Top780;

      case 800:
        return Top800;

      case 801:
        return Top800;

      case 802:
        return Top800;

      case 803:
        return Top800;

      case 804:
        return Top800;

      case 805:
        return Top800;

      case 810:
        return Top800;

      case 900:
        return Top900;

      case 901:
        return Top901;

      case 902:
        return Top902;

      case 903:
        return Top903;

      case 904:
        return Top904;

      case 910:
        return Top910;

      case 911:
        return Top911;

      case 912:
        return Top912;

      case 913:
        return Top913;

      case 914:
        return Top914;

      case 915:
        return Top915;

      case 916:
        return Top916;

      case 917:
        return Top917;

      case 918:
        return Top918;

      case 919:
        return Top919;

      case 920:
        return Top920;

      case 921:
        return Top921;

      case 922:
        return Top922;

      case 923:
        return Top923;

      case 924:
        return Top924;

      case 925:
        return Top925;

      case 926:
        return Top926;

      case 927:
        return Top927;

      case 928:
        return Top928;

      case 929:
        return Top929;

      case 930:
        return Top930;

      case 931:
        return Top931;

      case 932:
        return Top932;

      case 933:
        return Top933;

      case 934:
        return Top934;

      case 1001:
        return Top1001;

      case 1002:
        return Top1002;

      case 1003:
        return Top1003;

      case 1004:
        return Top1004;

      case 1011:
        return Top1011;

      case 1012:
        return Top1012;

      case 1013:
        return Top1013;

      case 1014:
        return Top1014;

      case 1021:
        return Top1021;

      case 1022:
        return Top1022;

      case 1023:
        return Top1023;

      case 1024:
        return Top1024;

      case 1031:
        return Top1031;

      case 1032:
        return Top1032;

      case 1033:
        return Top1033;

      case 1034:
        return Top1034;

      case 1100:
        return Top1100;

      case 1101:
        return Top1101;

      case 1102:
        return Top1102;

      case 1103:
        return Top1103;

      case 1104:
        return Top1104;

      case 1105:
        return Top1105;

      case 1106:
        return Top1106;

      case 1107:
        return Top1107;

      case 1108:
        return Top1108;

      case 1109:
        return Top1109;

      case 1201:
        return Top1201;

      case 1202:
        return Top1202;

      case 1203:
        return Top1203;

      case 1204:
        return Top1204;

      case 1205:
        return Top1205;

      case 1206:
        return Top1206;

      case 1207:
        return Top1207;

      case 1208:
        return Top1208;

      case 1209:
        return Top1209;

      case 1301:
        return Top1301;

      default:
        return Top777_1;
    }
  }

  componentDidMount() {
    document.getElementById("favicon").setAttribute("href", getFavicon());

    adblockDetect().then(detected => {
      window.ab = detected;

      loadConfig().then(config => {
        if (window.parent === window.self) {
          const url = new URL(window.location.href);

          if (config.config.click_id) {
            url.searchParams.set("click_id", config.config.click_id);
          }

          if (config.config.domain) {
            url.searchParams.set("domain", config.config.domain);
          }

          window.history.replaceState(null, "", url.toString());
        }

        const nextState = {
          configured: true,
          configuration: config.config,
          mainNews: config.teaser || null,
          redirect: config.redirect || false
        };
        this.setState(nextState);

        const brd = new BackRedirectDetect(
          nextState.mainNews,
          this.getConfiguration()
        );
        brd.setInfo();
      });
    });
  }

  getConfiguration() {
    return getConfig(this.state.configuration);
  }

  render() {
    if (!this.state.configured) {
      return <CookieFrame />;
    }

    if (this.state.redirect) {
      return (
        <React.Fragment>
          <CookieFrame />
          <Redirect url={this.state.redirect} />
        </React.Fragment>
      );
    }

    const { cid, template } = this.state.configuration;

    const enableLiRu = cid !== 2515;
    const enableYaMetrica = cid !== 2515;
    const enableSmiWebpush =
      WEBPUSH_DISABLED_SOURCES.indexOf(cid.toString()) === -1 &&
      template !== 1301;
    const enableReTarget = cid === 14111 || cid === 2515;

    const View = this.getView();

    const config = this.getConfiguration();

    return (
      <ConfigContext.Provider value={config}>
        <CookieFrame />
        {enableLiRu && <LiRuCounter />}
        {enableYaMetrica && <YaMetrica />}
        {enableSmiWebpush && <SmiWebpush />}
        {enableReTarget && <ReTarget />}

        <TrackerProvider>
          <View
            configuration={config}
            mainNews={this.state.mainNews}
            updateMainNews={this.updateMainNews.bind(this)}
            yaCounterReady={this.state.yaCounterReady}
            enableYaMetrica={enableYaMetrica}
          />
        </TrackerProvider>
      </ConfigContext.Provider>
    );
  }
}

export default App;
